.tokenInfo {
  position: absolute;
  top: 6rem;
  right: unset;
  left: 0;
  width: 360px;
  background: rgba(45, 34, 104, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(24px);
  border-radius: 4px;
  .search {
    height: 48px;
    padding: 0 10px 0 16px;
    background: rgba(0, 0, 0, 0.2);
  }
  .typeTab {
    padding: 8px;
    .tabItem {
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 4px;
    }
    .tabItemActive {
      background: rgba(155, 150, 255, 0.2);
    }
  }
  .token_th {
    height: 36px;
    padding: 0 16px;
  }

  .change {
    width: 90px;
  }

  .pair {
    width: 120px;
  }

  .tokenList {
    height: 280px;
    overflow: auto;
    .tokenItem {
      height: 48px;
      padding: 0 16px;
      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }
}

.tokenList::-webkit-scrollbar {
  width: 4px;
  background: rgba(45, 34, 104, 0.4);
}

.tokenList::-webkit-scrollbar-track {
  background: 0 0;
}

.tokenList::-webkit-scrollbar-thumb {
  background: #5e58db;
  border-radius: 16px;
}

.tokenList::-webkit-scrollbar-thumb:hover {
  background: #5e58db;
}
