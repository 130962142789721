.w-812 {
  width: 812px;
}

.w-432 {
  width: 432px;
}

.dialogBg {
  max-width: 100%;
  line-height: 20px;

  &.iswap {
    width: 432px;
  }

  .dialogHeader {
    padding-left: 16px;
    padding-right: 18px;
    background: rgba(0, 0, 0, 0.2);
    height: 60px;
    text-transform: uppercase;
  }

  .searchBox,
  .tokenItem,
  .unstaking,
  .summary2,
  .payInfo {
    border-bottom: 1px solid #20253e;
  }

  .searchBox {
    padding: 36px 40px 30px;

    .TokenSelector-token-row {
      padding-bottom: 0;
      height: 72px;
    }
  }

  .settingInfo {
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    .slippage {
      padding: 16px 32px 16px 16px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      .slippageAmount {
        .amount-input {
          padding: 24px 16px 18px;
          background: rgba(0, 0, 0, 0.2);
          mix-blend-mode: normal;
          border-radius: 10px;
        }
      }
    }

    .displayItem {
      height: 48px;
      padding: 0 16px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  }

  .saveBtnBox {
    margin: 32px;
  }

  .dialogInfo {
    .tab2 {
      margin-bottom: 18px;

      .tabItem {
        border: 1px solid #9b96ff;
        width: 110px;
        height: 36px;
        border-radius: 5px;

        &:hover {
          cursor: pointer;
        }
      }

      .tabItemActive {
        background: #9b96ff;
        color: #ffffff;
      }
    }

    .buyTab {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);

      .buyTabItem {
        position: relative;
        height: 40px;
      }

      .buyTabItemActive {
        color: rgba(238, 242, 249);

        &::after {
          position: absolute;
          content: "";
          bottom: 0px;
          right: 0;
          left: 0;
          top: 0;
          margin: 0 auto;
          width: 20px;
          height: 4px;
          transform: translateY(36px);
          background: linear-gradient(180deg, #ffd100 0%, #f89542 100%);
        }
      }
    }

    .Exchange-swap-section {
      padding: 16px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.2);
    }

    .Exchange-swap-section-top {
      color: rgb(238, 242, 249, 0.5);
      font-size: 12px;
    }

    .Exchange-swap-max {
      position: static;
      width: 50px;
      height: 22px;
      line-height: 22px;
      background: rgba(248, 149, 66, 0.1);
      border-radius: 4px;
      cursor: pointer;
      color: #f89542;
      font-size: 12px;
      font-family: "Poppins-ExtraBold";
    }
  }

  .sellers {
    padding: 16px 16px 0;
    display: flex;
    justify-content: flex-start;
  }

  .stakedAmount {
    .amount-input {
      line-height: 30px;
      padding: 0;
    }
  }

  .max {
    width: 50px;
    height: 22px;
    background: rgba(248, 149, 66, 0.1);
    border-radius: 4px;
    cursor: pointer;
  }

  .summary {
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 8px;
  }

  .burn {
    padding: 18px 40px 0 46px;
  }

  .unstaking {
    padding: 0 40px 24px 46px;
  }

  .infoBg {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    .balance {
      padding: 0 8px;
      height: 22px;
      line-height: 22px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 4px;
    }
  }

  .vestingTerms {
    padding: 16px 16px 40px;
  }

  .staking {
    .timeTab {
      height: 58px;
      padding: 0 12px;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 8px;

      .tabItem {
        height: 36px;
        cursor: pointer;
      }

      .tabItemActive {
        background: #f89542;
        border-radius: 4px;
        color: #ffffff;
      }
    }
  }

  .padding-16 {
    padding: 16px;
  }

  .stakeInfo {
    .stakeInfo-l {
      width: 400px;
      max-width: 100%;
    }
  }

  .stakeInfo-elp {
    padding: 0 32px;
  }

  .fees {
    padding: 0 16px;
  }

  .sliderBox {
    margin-top: 72px;

    .MuiSlider-root.Mui-disabled {
      color: #9b96ff !important;
    }

    .MuiSlider-rail,
    .MuiSlider-track {
      height: 8px;
      border-radius: 4px;
    }

    .MuiSlider-thumb.Mui-disabled {
      visibility: hidden;
    }
  }

  .receiveBg {
    height: 72px;
    padding: 0 30px;
    background: #101224;
    border-radius: 10px;
  }

  .payInfo {
    padding: 36px 40px 30px 46px;
  }

  .warnBox {
    background-color: #825914;

    .warnInfo {
      padding: 20px;
      background: url(../../assets/images/ocde.svg);
    }
  }

  .summaryInfo,
  .summaryInfo2,
  .summaryInfo3 {
    background: #20253e;
    border: 1px solid #20253e;
    border-radius: 10px;
  }

  .summaryInfo {
    padding: 20px 40px 24px;
  }

  .summaryInfo2 {
    padding: 20px 40px;
  }

  .summaryInfo3 {
    .box1 {
      padding: 20px 40px 16px;
    }

    .box2 {
      padding: 16px 40px;
    }

    .box3 {
      padding: 16px 40px 20px;
    }

    .box1,
    .box2 {
      border-bottom: 1px solid #3e4466;
    }
  }

  .rewards {
    padding: 18px 40px 36px 46px;

    .rewardInfo {
      .reward {
        flex: 1;
        min-height: 72px;
        background: #20253e;
        border: 1px solid #20253e;
        border-radius: 10px;
      }

      .add {
        margin: 0 16px;
      }
    }
  }

  .rewardBox {
    padding: 18px;
    background: #20253e;
    border: 1px solid #20253e;
    border-radius: 10px;

    .rewardTitle {
      line-height: 28px;
    }

    .rewardVal {
      line-height: 48px;
    }
  }

  .editBtnContainer button.App-cta {
    background: #9b96ff;
    border: 1px solid #9b96ff;
  }

  .networkList {
    margin: 16px;
    padding: 16px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    .networkItem {
      padding: 16px;
      height: 48px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .isConnected {
        .connected {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #0ad5bd;
        }
      }
    }

    .networkItemActive {
      background: rgba(219, 151, 20, 0.1);
      border: 1px solid #db9714;
    }
  }
}

.dialogBg-wallet {
  width: 500px;
}

.dialogBg-network {
  width: 400px;
}

.MuiDialog-paper {
  margin: 0 !important;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.pay_swap {
  height: 16px;
  position: relative;

  .pay_icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    transform: translateY(-18px);
    z-index: 4;
  }
}

.p_dtail {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dtail_lt {
    font-size: 14px;
    color: rgba(238, 242, 249, 0.5);
    border-left: 4px solid #2f2d6c;
    padding-left: 8px;
  }

  .dtail_rt {
    font-size: 14px;
    color: #eef2f9;
  }
}

.open_position {
  display: flex;
  justify-content: flex-start;
}

.pay_dtail {
  padding: 0 16px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.position_right {
  padding-right: 16px;
  padding-top: 16px;
  flex: 1;

  .rg_box {
    padding: 16px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
}

.confirm_cat {
  border-radius: 0 0 10px 10px;
}

.sellrg_box {
  padding-left: 16px;
  flex: 1;
  border: 1px;

  .boders_sell {
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    padding: 16px 16px 20px;

    .Triggers {
      margin-bottom: 12px;
    }

    .p_list {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .sell_line {
      border: 1px solid rgba(255, 255, 255, 0.08);
      margin: 4px 0;
    }
  }
}

.g_flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .f_size {
    display: flex;
    gap: 8px;
    align-items: center;
    color: rgba(238, 242, 249, 0.5);
  }
}

.eid_order {
  padding: 0 16px;
}

@media only screen and (max-width: 1280px) {
  .dialogBg {
    .stakeInfo {
      .stakeInfo-l,
      .stakeInfo-r {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .dialogBg {
    width: 92vw;

    .padding-16 {
      padding: 12px;
    }

    .open_position {
      flex-direction: column;

      .pay_dtail {
        padding-inline-start: 0;
      }

      .position_right {
        padding-left: 16px;
      }
    }

    .sellers {
      .h5sellers {
        flex-direction: column;
      }
    }

    .sellrg_box {
      padding: 0;
      margin-top: 14px;
    }

    .dialogBg-wallet {
      width: 300px;

      .connectBtnBox,
      .getBtnBox {
        width: 100%;
      }

      .getBtnBox {
        margin-top: 12px;
      }
    }

    .dialogBg-network {
      width: 90vw;
    }

    .networkList {
      margin: 20px 16px;
    }
  }
}
